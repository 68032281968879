.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden; }
  .ant-carousel .slick-slide .banner-item {
    position: relative; }

.ant-carousel .slick-slide .banner-link {
  width: 100%;
  display: inline-block;
  background-size: cover;
  background-position: center; }

.ant-carousel .slick-slide .banner-text {
  font-size: 48px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
