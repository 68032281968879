.card-wrapper {
  display: inline-block;
  padding: 10px 10px; }

a.course-card {
  display: inline-block;
  position: relative;
  transition: all .3s;
  background-color: #fff;
  text-align: center;
  width: 100%; }
  a.course-card img {
    width: 100%;
    height: 170px; }
  a.course-card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, -4px, 0); }
    a.course-card:hover .course-name {
      color: #55bdf7; }

.course-type {
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0 9px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  color: #fff; }

.course-name {
  display: inline-block;
  padding: 0 10px;
  margin: 15px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 42px;
  line-height: 21px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  transition: all .3s; }
