footer {
  background-color: #3d3d3d;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 100px;
  font-size: 14px; }

.footer-wrapper {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center; }
  .footer-wrapper .foot-logo {
    padding: 15px 0; }
    .footer-wrapper .foot-logo a {
      display: block;
      padding: 10px 0; }
    .footer-wrapper .foot-logo .edx {
      width: 105px; }
    .footer-wrapper .foot-logo .openedx {
      width: 103px; }
  .footer-wrapper .secondary {
    margin-left: 50px; }
    .footer-wrapper .secondary p {
      padding: 15px; }
    .footer-wrapper .secondary a {
      color: rgba(255, 255, 255, 0.6);
      margin-right: 10px; }
      .footer-wrapper .secondary a:hover {
        color: #fff; }
