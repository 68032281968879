.subportal-wrapper {
  margin: 50px 0;
  display: flex;
  align-items: center; }
  .subportal-wrapper .subportal-arrow {
    width: 40px;
    height: 80px;
    line-height: 80px;
    vertical-align: bottom;
    font-size: 18px;
    text-align: center;
    background-color: #fff;
    display: inline-block;
    cursor: pointer;
    box-shadow: 2px 2px 2px #ddd;
    transition: .2s; }
    .subportal-wrapper .subportal-arrow.enabled:hover {
      transform: scale(1.05);
      box-shadow: 4px 4px 4px #ddd;
      color: #55bdf7; }
    .subportal-wrapper .subportal-arrow.disabled {
      color: #ddd;
      cursor: not-allowed; }
  .subportal-wrapper .arrow-left {
    margin-right: 30px; }
  .subportal-wrapper .arrow-right {
    margin-left: 30px; }
  .subportal-wrapper ul {
    display: flex;
    width: calc(100% - 140px); }
    .subportal-wrapper ul li {
      display: inline-block;
      font-size: 16px;
      margin: 0 20px;
      box-shadow: 2px 2px 8px #ddd; }
      .subportal-wrapper ul li:last-child {
        margin-right: 0; }
      .subportal-wrapper ul li:first-child {
        margin-left: 0; }
  .subportal-wrapper .subportal-name {
    color: rgba(0, 0, 0, 0.65);
    transition: .3s;
    text-align: center;
    display: inline-block;
    width: 100%; }
    .subportal-wrapper .subportal-name:hover {
      color: #02bffc; }
    .subportal-wrapper .subportal-name span {
      display: inline-block;
      width: 100%;
      height: 80px;
      line-height: 80px;
      vertical-align: middle;
      padding: 0 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .subportal-wrapper .subportal-name img {
      height: 80px; }
